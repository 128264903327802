var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.tempAttachments.length > 0 ? _c('ul', {
    staticClass: "attachmentUl"
  }, _vm._l(_vm.tempAttachments, function (tempAttachment) {
    return _c('li', {
      key: tempAttachment.id
    }, [tempAttachment.uploadStatus == true ? _c('label', {
      attrs: {
        "id": "attachmentLiSuccess"
      }
    }, [_c('div', {
      staticClass: "file-details"
    }, [_c('div', {
      staticClass: "file-name display-flex align-center"
    }, [_c('p', {
      ref: "attachments",
      refInFor: true
    }, [_vm._v(_vm._s(tempAttachment.name))])]), _c('div', {
      staticClass: "file-info"
    }, [_c('span', {
      staticClass: "uploaded-date"
    }, [_vm._v(" " + _vm._s(tempAttachment.size) + " ")])])])]) : tempAttachment.uploadStatus == false ? _c('label', {
      attrs: {
        "id": "attachmentLiError"
      }
    }, [_c('div', {
      staticClass: "file-details"
    }, [_c('div', {
      staticClass: "file-name display-flex align-center"
    }, [_c('p', {
      ref: "attachments",
      refInFor: true
    }, [_vm._v(_vm._s(tempAttachment.name))])]), _c('div', {
      staticClass: "file-info"
    }, [_c('span', {
      staticClass: "uploaded-date"
    }, [_vm._v(" " + _vm._s(tempAttachment.size) + " ")])])])]) : _vm._e()]);
  }), 0) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }