<template>
  <div class="py-4">
    <b-card header="Bulk Upload" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
      <div id="myDropzone" class="dropzone">
        <div v-if='!this.isUpload'>
          <vue-dropzone
            ref="DropZoneData"
            :include-styling="false"
            :useCustomSlot="true"
            id="dropzoneItems"
            :options="dropzoneOptions"
            @vdropzone-files-added="filesUpload"
            @vdropzone-file-added="fileAdded"
            
            >
            <div class="dropzone-container">
              <div class="file-selector">
                <Icon></Icon>
                <i class="bi bi-upload">Drop Files Here</i>
                <br/>
                <span> or </span>
                <br/>
                <button class="btn buttonBrowse" type="button">Browse</button>
              </div>
            </div>
          </vue-dropzone>
          </div>
          <div v-else>
            <div class="card" >
              <div class="card-body">
                <h5 class="card-title">  Status : {{ this.statusUpload }} </h5>
                <h6 >Proccesed : {{ this.indexUpload }} / {{ this.lengthUpload }}</h6>
                <h6  style="color:#0060AF">Success : {{ this.countRight }} / {{ this.lengthUpload }}</h6>
                <h6  style="color:#df4759">Failed : {{ this.countWrong }} / {{ this.lengthUpload }}</h6>
                <b-overlay
                :show="isOverlay"
                  rounded="lg"
                  opacity="0.6"
                  spinner-variant="primary"
                >
                  <AttachmentList
                    :tempAttachments="getTempAttachments"
                    :attachments="getAttachments"
                  />
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </b-card>
      <div v-if='this.isData'>
      <ReviewData
      :items="file_item">

      </ReviewData>
    <b-button class="fix-bot-right" size="lg" variant="warning"  @click="resetAction">
       Reset
    </b-button>
  </div>
</div>

</template>

<script>
import { mapActions } from 'vuex';
import { DetailItems } from '../../router/content';
import AttachmentList from '../../components/attachment/AttachmentList.vue';
import Icon from '../../components/attachment/icon.vue';
import ReviewData from '../../components/attachment/ReviewData.vue';

export default {
  name : 'UploadBulk',
  components: {
    AttachmentList,
    Icon,
    ReviewData,
},
  data() {
    return {
      DetailItems,
      statusUpload:'Uploading',
      indexUpload:0,
      lengthUpload : 0,
      isData:false,
      isUpload:false,
      countWrong:0,
      countRight:0,
      file_item:[],
      isOverlay :false,
      tempAttachments: [],
      dropzoneOptions: {
        url: `https://httpbin.org/post`, // this is neccessary but unused
        maxFilesize: 157286400, // 150 mega bytes
        paramName: function() {
          return "file[]";
        },
        includeStyling: false, 
        autoProcessQueue: false,
        previewsContainer: false, // to not set default dropzone buble box
        uploadMultiple: true,
        acceptedFiles: ".pdf",
        parallelUploads: 20,
        
      }
    };
  },
  computed: {
    getTempAttachments() {
      return this.tempAttachments;
    },
    getAttachments() {
      return this.attachments;
    }
  },
  
  methods: {
    ...mapActions('bulkUpload', ['checkEditionCode','uploadBulk']),
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },checkFiles() {
      this.isData = true;
    },
    async fileAdded() {
      const attachment = {};
      this.tempAttachments = [...this.tempAttachments, attachment];
      this.isUpload = true;
      this.isOverlay = true;
      // to add file into array of maps then continue upload in fileUpload()
    },
    async filesUpload(file) {
      let i = 0;
      this.lengthUpload = file.length;
      while(i<file.length) {
        let editionCode = '';
        let uploadStatus = false;
        let messageFix = '';
        let fileName = file[i].name;
        let formatFile = file[i].type.split("/")[1];
        this.indexUpload = i+1;
        editionCode = fileName.replace('.pdf','');
        const response = await this.checkEditionCode({
          editionCode,
        });
        response.file_content = file[i];
        if(response.result) {
          const res = await this.uploadBulk(response);
          if(res.result) {
            uploadStatus = true;
          }
          messageFix =  res.message;
        } 
        const attachment = {};
        attachment.id = i;
        attachment.nameItems = response.name;
        attachment.extension = "." + formatFile;
        attachment.size = this.formatBytes(file[i].size);
        attachment.name = file[i].name;
        attachment.editionCode = file[i].name.replace('.pdf','');
        attachment.indexUpload  = i+1;
        attachment.lengthUpload = file.length-1;
        if(formatFile!='pdf') {
          messageFix = 'Wrong Format Upload';
          uploadStatus = false;
        }
        if(file[i].size>157286400){
          messageFix = 'File Size Must Not Bigger than 150 MB';
          uploadStatus = false;
        }
        attachment.message = [{messageItems:messageFix}];
        attachment.uploadStatus = uploadStatus;
        this.file_item.push(attachment);
        this.tempAttachments = [...this.tempAttachments, attachment];
        this.isData = true;
        if(uploadStatus){
          this.countRight += 1;
        }else{
          this.countWrong += 1;
        }
        if(i==file.length-1){
          this.isOverlay = false;
          this.statusUpload = 'Done Upload';
          break;
        }
        i++;
      }
    },
    resetAction() {
      this.statusUpload='Uploading';
      this.indexUpload=0;
      this.lengthUpload = 0;
      this.isData=false;
      this.isUpload=false;
      this.file_item=[];
      this.countWrong=0;
      this.countRight=0;
      this.tempAttachments= [];
    },
    messageAlert(icon, title, timer = 3000) {
    this.$swal({
      toast: 'true',
      position: 'top-end',
      icon,
      title,
      showConfirmButton: false,
      timer,
      timerProgressBar: true,
    });
  }

  }
}
</script>
<style>
.file-selector {
  padding: 55px;
  font-weight: 600;
  background-color: #f9f9f9;
  color: #4e5b69;
  width: 100%;
  height: 100%;
  
}
.buttonBrowse {
  background: #2B65EC;
  box-shadow: 0 0 2px 0 rgba(3, 22, 41, 0.11),
    0 6px 16px 0 rgba(3, 22, 41, 0.08);
  font-family: SFProDisplay-Regular;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.4px;
  padding: 12px 30px;
  border-radius: 4px;
}
.dropzone-container {
  display: flex;
  flex-direction: column;
  border: 1px dashed #ccc;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.separator {
  position: relative;
}
.separator:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 200px;
  background: #d8d8d8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.span {
  position: relative;
  background: #f9f9f9;
  padding: 0 4px;
  font-size: 12px;
  color: red;
}
.fix-bot-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 0 10px white;
}

</style>
