var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "header": "Review Data",
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "items": _vm.items,
      "fields": _vm.fields,
      "show-empty": "",
      "responsive": "xl",
      "hover": "",
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(uploadStatus)",
      fn: function (row) {
        return [_c('div', [row.item.uploadStatus ? _c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v("Success Upload")]) : _c('span', {
          staticClass: "badge badge-danger"
        }, [_vm._v(" Failed Upload ")])])];
      }
    }, {
      key: "cell(message)",
      fn: function (row) {
        return [_c('div', [_c('b-table', {
          attrs: {
            "items": row.item.message,
            "fields": _vm.fieldsChapter,
            "thead-class": "hidden_header"
          },
          scopedSlots: _vm._u([{
            key: "cell(messageItem)",
            fn: function (rowChapter) {
              return [_c('div', [_vm._v(" " + _vm._s(rowChapter.item.messageItems) + " "), rowChapter.item.result == true && _vm.type == 'audiobook' ? _c('span', {
                staticClass: "badge badge-success"
              }, [_c('b-button', {
                attrs: {
                  "variant": "primary"
                },
                on: {
                  "click": function ($event) {
                    return _vm.editChapter({
                      itemName: row.item.nameItems,
                      chapter: rowChapter.item.chapter,
                      item_id: row.item.item_id
                    });
                  }
                }
              }, [_vm._v("Edit Chapters")])], 1) : _vm._e()])];
            }
          }], null, true)
        }, [_vm._v(" row item" + _vm._s(row.item) + " ")]), _c('b-overlay', [_c('b-modal', {
          attrs: {
            "size": "lg",
            "id": "modal-edit-chapter",
            "centered": "",
            "title": _vm.chapter.header + " Chapter (" + _vm.chapter.chapter + ")",
            "hide-footer": ""
          }
        }, [_c('b-form', {
          on: {
            "submit": _vm.onSubmitChapter
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "t_chapter",
            "label": "Title Chapter"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "t_chapter",
            "type": "text",
            "placeholder": "Enter Title",
            "required": ""
          },
          model: {
            value: _vm.chapter.title,
            callback: function ($$v) {
              _vm.$set(_vm.chapter, "title", $$v);
            },
            expression: "chapter.title"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-form-group', [_c('b-form-checkbox', {
          model: {
            value: _vm.chapter.is_sample,
            callback: function ($$v) {
              _vm.$set(_vm.chapter, "is_sample", $$v);
            },
            expression: "chapter.is_sample"
          }
        }, [_vm._v("Show Sample ")])], 1)], 1)], 1), _vm.chapter.is_sample ? _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "minimum_level",
            "label": "Minimum Level"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "minimum_level",
            "type": "number",
            "placeholder": "Enter Title"
          },
          model: {
            value: _vm.chapter.minimum_level,
            callback: function ($$v) {
              _vm.$set(_vm.chapter, "minimum_level", $$v);
            },
            expression: "chapter.minimum_level"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "t_from",
            "label": "From (minutes)"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "t_from",
            "type": "number",
            "placeholder": "Enter Title"
          },
          model: {
            value: _vm.chapter.time_from,
            callback: function ($$v) {
              _vm.$set(_vm.chapter, "time_from", $$v);
            },
            expression: "chapter.time_from"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "t_to",
            "label": "To (minutes)"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "t_to",
            "type": "number",
            "placeholder": "Enter Title"
          },
          model: {
            value: _vm.chapter.time_to,
            callback: function ($$v) {
              _vm.$set(_vm.chapter, "time_to", $$v);
            },
            expression: "chapter.time_to"
          }
        })], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-form-group', [_c('b-button', {
          staticClass: "text-white",
          attrs: {
            "variant": "success",
            "type": "submit"
          }
        }, [_vm._v(" Edit Chapter ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }