<template>
    <div>
      <ul class ="attachmentUl" v-if="tempAttachments.length > 0">
        <li v-for="tempAttachment in tempAttachments" :key="tempAttachment.id">
          <label v-if="tempAttachment.uploadStatus==true" id = "attachmentLiSuccess" > 
            <div class="file-details">
              <div class="file-name display-flex align-center">
                <p class="" ref="attachments">{{ tempAttachment.name }}</p>
              </div>
              <div class="file-info">
                <span class="uploaded-date"> {{ tempAttachment.size }}  </span>
              </div>
            </div>
          </label>
          
          <label v-else-if="tempAttachment.uploadStatus==false" id = "attachmentLiError"> 
            <div class="file-details">
              <div class="file-name display-flex align-center">
                <p class="" ref="attachments">{{ tempAttachment.name }}</p>
              </div>
              <div class="file-info">
                <span class="uploaded-date"> {{ tempAttachment.size }}  </span>
              </div>
            </div>
          </label>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "AttachmentList",
    props: {
      tempAttachments: {
        type: Array
      },
      attachments: {
        type: Array
      },
    },
    };
  </script>
  
  <style>
  ul.attachmentUl {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0px;
  }
  #attachmentLiError{
    border: 1px solid;
    margin-bottom: 8px;
    border-radius: 8px;
    margin-right: 8px;
    padding: 0 10px;
    color: white;
    background-color: #df4759;
  }
  #attachmentLiSuccess{
    border: 1px solid;
    margin-bottom: 8px;
    border-radius: 8px;
    margin-right: 8px;
    padding: 0 10px;
    color: white;
    background-color:  #0060AF;
  }
  #attachmentLiOpen{
    border: 1px solid;
    margin-bottom: 8px;
    border-radius: 8px;
    margin-right: 8px;
    padding: 0 10px;
    color: black;
    background-color:  peru;
  }
  .file-name {
    min-width: 150px;
    font-size: 13px;
  }
  .uploaded-date {
    font-size: 12px;
  }
  .upload-progress {
    font-size: 12px;
  }
  .file-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .hiddenClass {
    display: none;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  </style>
  