<template>
  <b-card header="Review Data" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
    <b-row>
      <b-col cols='12'>
          <b-table 
          sticky-header="500px"
          :items="items"
          :fields="fields"
          show-empty
          responsive="xl"
          hover
          sort-icon-left
        >
        <template v-slot:cell(uploadStatus)="row">
          <div>
            <span v-if="row.item.uploadStatus" class="badge badge-success">Success Upload</span>
            <span v-else class="badge badge-danger"> Failed Upload </span>
          </div>
        </template>
        <template v-slot:cell(message) = "row">
          <div>
            <b-table :items="row.item.message"  :fields="fieldsChapter" thead-class="hidden_header">
              row item{{ row.item }}
              <template v-slot:cell(messageItem)="rowChapter">
                <div>
                  {{rowChapter.item.messageItems}}
                  <span v-if="rowChapter.item.result==true && type=='audiobook'"  class="badge badge-success">
                    <b-button variant='primary' @click = "editChapter({itemName:row.item.nameItems, chapter:rowChapter.item.chapter,item_id: row.item.item_id})">Edit Chapters</b-button>
                  </span>
                </div>
              </template>
            </b-table>
            
            <b-overlay>
              <b-modal size="lg" id="modal-edit-chapter" centered :title="chapter.header + ` Chapter (` + chapter.chapter + `)`"
                    hide-footer>
                <b-form @submit="onSubmitChapter">

                  <b-row>
                    <b-col cols="12">
                      <b-form-group label-for="t_chapter" label="Title Chapter">
                        <b-form-input id="t_chapter" v-model="chapter.title" type="text" placeholder="Enter Title" required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group>
                        <b-form-checkbox v-model="chapter.is_sample">Show Sample
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="chapter.is_sample">
                    <b-col cols="4">
                      <b-form-group label-for="minimum_level" label="Minimum Level">
                        <b-form-input id="minimum_level" v-model="chapter.minimum_level" type="number" placeholder="Enter Title">
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group label-for="t_from" label="From (minutes)">
                        <b-form-input id="t_from" v-model="chapter.time_from" type="number" placeholder="Enter Title">
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group label-for="t_to" label="To (minutes)">
                        <b-form-input id="t_to" v-model="chapter.time_to" type="number" placeholder="Enter Title">
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <b-button variant="success" class="text-white" type="submit">
                          Edit Chapter
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-modal>
            </b-overlay>
          </div>
        </template>
      </b-table>
    </b-col>
  </b-row>
</b-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'TableUpload',
  data() {
    return {
    fields: [
        { key: 'nameItems', label: 'Name Items'},
        { key: 'editionCode', label: 'Edition Code'},
        { key: 'size', label: 'Size'},
        { key: 'extension', label: 'Extension' },
        { key: 'uploadStatus', label: 'Upload Status' },
        { key: 'message', label: 'Message', style:{'width':'80%'} },
      ],
      fieldsChapter: [
        { key:'messageItem', label: 'Message'},
      ],
      chapter: {
        chapter: null,
        title: null,
        is_sample: false,
        minimum_level: 0,
        time_from: 0,
        time_to: 0,
        item_id:null,
      },
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    type:{
      type:String,
    }
  },
  computed: {
    ...mapState({
      isError: (state) => state.audioBooks.isError,
      isLoading: (state) => state.audioBooks.isLoading,
      errorMessage: (state) => state.audioBooks.errorMessage,
      successMessage: (state) => state.audioBooks.successMessage,
      item: (state) => state.audioBooks.item,
      refresh: (state) => state.audioBooks.refresh,
      audioItem: (state) => state.audioBooks.itemAudio,
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
    },
    successMessage: function () {
      if (!this.successMessage) return;
    },
  
  },
  methods: {
    ...mapActions('bulkUpload', ['checkChapter']),
    ...mapActions('audioBooks', ['updateChapter']),
    async onSubmitChapter(event) {
      event.preventDefault();
      this.chapter.is_edit = true;
      const update = await this.updateChapter(this.chapter);
      if(update.result == true) {
        this.$bvModal.hide('modal-edit-chapter');
        return this.baseAlert({
          icon: 'success',
          title: 'Success Updated',
          text: `${update.message}`,
        });
      } else {
        return this.baseAlert({
          icon: 'error',
          title: 'Failed Updated',
          text: `${update.message}`,
        });
      }
    },
    async editChapter(data) {
      const chapter = await this.checkChapter({item_id : data.item_id, chapter: data.chapter});
      if(chapter.result == false) {
        return this.baseAlert({
          icon: 'error',
          title: 'Error Find Chapter',
          text: `${chapter.message}`,
        });
      } else {
        this.chapter.header = data.itemName;
        this.chapter.chapter = chapter.item.chapter;
        this.chapter.title = chapter.item.title;
        this.chapter.is_sample = chapter.item.detailAudio.is_sample;
        this.chapter.minimum_level = chapter.item.detailAudio.minimum_level;
        this.chapter.time_to =  chapter.item.detailAudio.duration_to / 6000;
        this.chapter.time_from = chapter.item.detailAudio.duration_from / 6000;
        this.chapter.item_id =  data.item_id;
        this.$bvModal.show('modal-edit-chapter');
      }
      
    },
    baseAlert(data) {
      this.$swal({
        icon: data.icon,
        title: data.title,
        text: data.text,
      })
    },
  }
}
</script>

<style>
.hidden_header {
  display: none;
}
</style>